import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  reducerPath: 'api', // optional
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        return headers
      }
    },
  }),
  tagTypes: [
    'subs',
    'getAllFilmings',
    'getFeaturedSlice',
    'getAllProjects',
    'getAllProfiles',
    'getPost',
    'getAllPosts',
    'getManufacturers',
    'getCategories',
    'getPurpose',
    'getProduct',
    'getAllProducts',
  ],
  endpoints: (builder) => ({}),
})
